import { template as template_340d3da0577b426bbbe784c0649a684f } from "@ember/template-compiler";
const FKLabel = template_340d3da0577b426bbbe784c0649a684f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
