import { template as template_05afc8230a33459691bc826b3a2a42bd } from "@ember/template-compiler";
const SidebarSectionMessage = template_05afc8230a33459691bc826b3a2a42bd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
